@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-bg: #141E30;
  --color-text-white: #fff;
  --color-text-blue: #003051;
  --color-button-hover: #005690;
  --color-button-disabled: #707d86;
  --color-input-focus: #003051;
  --white: #fff;
  --blue: #003051;
  --blue200: #003051;
  --yellow: #FFFBD1;
  --yellow200: #b1aa5d;
  --grey100: #D4D4D4;
  --grey200: #9D9D9D;
  --grey300: #333741;
  --black100: #000;
  --black200: #212121;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  margin-bottom: 15px;
  font-family: Nunito;
  font-size: 16px;
}

a {
  color: var(--color-a);
  white-space: nowrap;
}

.no-scroll {
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]){
  border-top-width: 0px;
}

.btn-balance{
  border-radius: 0px !important;
}

.btn-balance-left{
  border-right: solid 1px rgb(255 255 255 / 0.1) !important;   
  border-bottom-left-radius: 5px !important;
}

.btn-balance-right{
  border-bottom-right-radius: 5px !important;
}

.filter-white{
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
}

.scrollbar-x-hide::-webkit-scrollbar { width: 0 !important }
.scrollbar-x-hide { overflow: -moz-scrollbars-none; }