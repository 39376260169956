
@font-face {
  font-family: "Nunito-Bold" ;
  src: local("Nunito-Bold"),url(../src/assets/fonts/Nunito-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito-SemiBold" ;
  src: local("Nunito-SemiBold"),url(../src/assets/fonts/Nunito-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito-Medium" ;
  src: local("Nunito-Medium"),url(../src/assets/fonts/Nunito-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito-ExtraBold" ;
  src: local("Nunito-ExtraBold"),url(../src/assets/fonts/Nunito-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito-Regular" ;
  src: local("Nunito-Regular"),url(../src/assets/fonts/Nunito-Regular.ttf) format("truetype");
}
@tailwind base;
@tailwind components;
@tailwind utilities;



